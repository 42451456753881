<template>
    <div class="PopupConfirmedTransferred" v-show="isVisible==true">
        <section class="prdImgPopup_wrap">
            <div class="row">
                <div>
                    <div class="subHead">
                        <h3># 전원등록 </h3>
                    </div>
                    <table class="table table-profile">
                        <colgroup>
                            <col width="20%">
                            <col width="80%">
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">연번</th>
                            <td class="text-left">{{ data.f_idx }}</td>
                        </tr>
                        <tr>
                            <th class="field">이름</th>
                            <td class="text-left">{{ data.f_patient_name }}</td>
                        </tr>
                        <tr>
                            <th class="field">생년월일</th>
                            <td class="text-left">{{$moment(data.f_patient_birth).format('YYYY-MM-DD') + '('+data.f_patient_sex +' / ' + data.f_patient_age +')' }}</td>
                        </tr>
                        <tr>
                            <th class="field">시설명</th>
                            <td class="text-left">

                              <select class="select_ansimi width150"
                                      v-model="f_fasility"
                              >
                                <option v-for="(data, index) in list_fasility" :key="index" :value="data.code">
                                  {{data.name}}
                                </option>
                              </select>


                            </td>
                        </tr>
                        <tr>
                            <th class="field">전원일</th>
                            <td class="text-left">
                                <div class="dataGroup">
                                    <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" :disabled-dates="disabledDates" v-model="f_move_date"></datepicker>
                                    <span class="icon_calendar"></span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="field">전원사유</th>
                            <td class="text-left">
                              <select class="select_ansimi width150"
                                      v-model="f_move_reaseon"
                              >
                                <option v-for="(data, index) in list_move_reason" :key="index" :value="data.code">
                                  {{data.name}}
                                </option>
                              </select>
<!--                                <input type="text" class='input type2 width-100' v-model="f_move_reaseon" />-->
                            </td>
                        </tr>
                        <tr>
                            <th class="field">이송여부</th>
                            <td class="text-left">
                                <label>
                                    <input type="checkbox" v-model="f_move_yn" true-value="Y"
                                           false-value="N" /> 완료
                                </label>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row text-center no-pdd-top">
                    <div class="pull-left">
                        <button type="button" class="btn btn-lg btn-default" @click="isVisible=false">취소</button>
                    </div>
                    <div class="pull-right">
                        <button type="button" class="btn btn-lg btn-primary" @click="sendFunc">저장</button>
                    </div>
                </div>
            </div>
        </section>
        <div class="dimmed" @click="isVisible=false"></div>
    </div>
</template>

<script>
    import datepicker from 'vuejs-datepicker';
    import {ko} from 'vuejs-datepicker/dist/locale'

    let minDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
    minDate.setDate(minDate.getDate() - 90);
    let maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 1);

    export default {
        name: 'PopupConfirmedTransferred',
        data() {
            return {
                disabledDates: {
                    to: minDate,
                    from: maxDate
                },
                ko : ko,
                isVisible:false,
                data:[],
                list_fasility : [],     // 시설별 리스트
                list_move_reason : [],     // 시설별 리스트
                f_fasility : '',        // 시설별 정보
                f_move_date : '',       // 전원일
                f_move_reaseon:'',      // 전원사유
                f_move_yn:''            // 이송여부
            }
        },
        components: { datepicker },
        // 계산된 속성
        computed: {
        },
        // 감시자
        watch: {
            f_fasility(_new) {
                const selectedData = this.list_fasility.find(item => item.code === _new);
                if (selectedData) {
                    this.f_fasility_upper_code = selectedData.upperCode;
                } else {
                    this.f_fasility_upper_code = ''; // 선택된 항목이 없을 경우 초기화
                }
            }
        },
        // $el 생성전
        created() {
        },
        // $el 생성후
        mounted() {

        },
        methods: {
            init(isVisible, obj, list_fasility, list_move_reason, page){
                let params = `{"fIdx":${obj.f_idx}}`;
                let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
                this.ajaxGet('nocAssign/detail?params=' + queryString, null, 'initResult');

                this.isVisible=isVisible;
                this.data = obj;
                this.page = page;

                this.list_fasility=list_fasility;
                this.list_move_reason=list_move_reason;

                /*
                this.f_fasility = obj.f_fasility_code;
                this.f_move_date = (obj.f_move_date != '0000-00-00 00:00:00') ? this.$moment(obj.f_move_date).format('YYYY-MM-DD')  : this.$moment(new Date()).format('YYYY-MM-DD');
                this.f_move_reaseon = obj.f_move_reaseon;
                this.f_move_yn = obj.f_move_yn;
                this.list_fasility=list_fasility;
                this.list_move_reason=list_move_reason;
                */
            },
            initResult(response){
                let result = response.list;
                console.log(result);

                this.data.f_idx = result.fidx;
                this.data.f_patient_name = result.fpatientName;
                this.data.f_patient_birth = result.fpatientBirth;
                this.data.f_patient_sex = result.fpatientSex;
                this.data.f_patient_age = result.fpatientAge;

                this.f_fasility = result.ffasilityCode;
                this.f_move_date = (result.fmoveDate != '0000-00-00 00:00:00') ? this.$moment(result.fmoveDate).format('YYYY-MM-DD')  : this.$moment(new Date()).format('YYYY-MM-DD');
                this.f_move_reaseon = result.fextra1; // 전원사유
                this.f_move_yn = result.fextra6; // 이송여부

                console.log(this.data);
            },
            sendFunc(){
                if (!this.f_move_date){
                    this.showAlert('', '전원일을 선택해 주세요.');
                    return false;
                }

                if (!this.f_fasility){
                    this.showAlert('', '시설명을 선택해 주세요.');
                    return false;
                }

                if (!this.f_move_reaseon){
                    this.showAlert('', '전원사유를 선택해 주세요.');
                    return false;
                }

                let data = {
                    "fIdx": this.data.f_idx,
                    "fFasilityCode" : this.f_fasility,
                    "fFasilityTypeCode": this.f_fasility_upper_code,
                    "fMoveDate": this.$moment(this.f_move_date).format('YYYY-MM-DD'),
                    "fExtra1" : this.f_move_reaseon,
                    "fExtra6" : this.f_move_yn,
                    "adminId": this.userDataAdminId
                }

                this.ajaxPost('nocAssign/moveUpdate', data,'sendFuncResult');
                /*
                let formData = new FormData();
                formData.append('api_name', 'patientHospitalMove');
                formData.append('f_idx', this.data.f_idx);
                formData.append('f_move_date', this.$moment(this.f_move_date).format('YYYY-MM-DD'));
                formData.append('f_fasility', this.f_fasility);
                formData.append('f_move_reaseon', this.f_move_reaseon);
                formData.append('f_move_yn', this.f_move_yn);
                formData.append('f_admin_id', this.userDataAdminId);

                this.ajaxPost('', formData,'sendFuncResult');
                */
            },
            sendFuncResult(response) {
                this.showAlert('', '저장 되었습니다.',"close");
            },
            close(){
                this.isVisible=false;
                this.$parent.searchListFunc(this.page);
            }
        }
    }
</script>
<style scoped>
    .prdImgPopup_wrap {position: fixed;left:50%;top:50%;transform: translate(-50%, -50%); z-index: 9999;zoom:1;width: 500px;
        background: #fff;
        padding: 0 20px 20px;}
    .dimmed{background-color: #000;opacity: 0.5;position: fixed;left: 0;top: 0;right: 0;bottom: 0;z-index: 999;}
</style>
