<template>
    <div class="PopupSeverity" v-show="isVisible==true">
        <section class="prdImgPopup_wrap">
            <div class="row">
                <div>
                    <div class="subHead">
                        <h3># 중증도 변경 </h3>
                    </div>
                    <table class="table table-profile">
                        <colgroup>
                            <col width="25%">
                            <col />
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">연번</th>
                            <td class="text-left">{{ data.f_idx }}</td>
                        </tr>
                        <tr>
                            <th class="field">이름</th>
                            <td class="text-left">{{ data.f_patient_name }}</td>
                        </tr>
                        <tr>
                            <th class="field">생년월일</th>
                            <td class="text-left">{{$moment(data.f_patient_birth).format('YYYY-MM-DD') + '('+data.f_patient_sex +' / ' + data.f_patient_age +')' }}</td>
                        </tr>
						<tr>
							<th class="field">중증도상태</th>
							<td class="text-left">
								<label v-for="(data, index) in list_symptom_state" :key="index"
									class="mrg-right-10"
								>
								<input type="checkbox"
									:value="data.code"
									v-model="f_symptom_state"
								/>
									{{data.name}}
								</label>

							</td>
						</tr>
                        </tbody>
                    </table>
                </div>
                <div class="row text-center no-pdd-top">
                    <div class="pull-left">
                        <button type="button" class="btn btn-lg btn-default" @click="isVisible=false">취소</button>
                    </div>
                    <div class="pull-right">
                        <button type="button" class="btn btn-lg btn-primary" @click="sendFunc">저장</button>
                    </div>
                </div>
            </div>
        </section>
        <div class="dimmed" @click="isVisible=false"></div>
    </div>
</template>

<script>
    export default {
        name: 'PopupSeverity',
        data() {
            return {
                isVisible:false,
                data:[],
                f_symptom_state : [],
                list_symptom_state:[],
            }
        },
        components: {  },
        // 계산된 속성
        computed: {
        },
        // 감시자
        watch: {
        },
        // $el 생성전
        created() {
        },
        // $el 생성후
        mounted() {

        },
        methods: {
            init(isVisible, obj, list_symptom_state, page) {
                let params = `{"fIdx":${obj.f_idx}}`;
                let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
                this.ajaxGet('nocAssign/detail?params=' + queryString, null, 'initResult');

                this.isVisible = isVisible;
                this.data = obj;

                // if (obj.f_symptom_type_code == ''){
                //   this.f_symptom_state = [];
                // }else{
                //   this.f_symptom_state = obj.f_symptom_type_code.split(',');
                // }

                this.list_symptom_state = list_symptom_state;
                this.page = page
            },
            initResult(response){
                let result = response.list;

                this.data.f_idx = result.fidx;
                this.data.f_patient_name = result.fpatientName;
                this.data.f_patient_birth = result.fpatientBirth;
                this.data.f_patient_sex = result.fpatientSex;
                this.data.f_patient_age = result.fpatientAge;
                this.f_symptom_state = result.fsymptomTypeCode.split(',').map(item => item.trim());
            },
            sendFunc() {
                if (this.f_symptom_state == ''){
                    this.showAlert('', '중증도상태를 선택해 주세요.');
                    return false;
                }

                let data = {
                    "fIdx": this.data.f_idx,
                    "fSymptomType1" : "N",
                    "fSymptomType2" : "N",
                    "fSymptomType3" : "N",
                    "fSymptomType4" : "N",
                    "fSymptomType5" : "N",
                    "fSymptomType6" : "N",
                    "fSymptomType7" : "N",
                    "fSymptomType8" : "N",
                    "fSymptomType9" : "N",
                    "adminId": this.userDataAdminId
                }

                this.f_symptom_state.forEach(el => {
                    if (el === "G0701") data.fSymptomType1 = "Y";
                    if (el === "G0702") data.fSymptomType2 = "Y";
                    if (el === "G0703") data.fSymptomType3 = "Y";
                    if (el === "G0704") data.fSymptomType4 = "Y";
                    if (el === "G0705") data.fSymptomType5 = "Y";
                    if (el === "G0706") data.fSymptomType6 = "Y";
                    if (el === "G0707") data.fSymptomType7 = "Y";
                    if (el === "G0708") data.fSymptomType8 = "Y";
                    if (el === "G0709") data.fSymptomType9 = "Y";
                });

                this.ajaxPost('/nocAssign/systomUpdate', data,'sendFuncResult');
                /*
                let formData = new FormData();
                formData.append('api_name', 'patientStateChange');  // API 이름
                formData.append('f_idx', this.data.f_idx);	                    // 확진자 기본키
                formData.append('f_chang_code', 'G0908');	        // 상태변경 분류값
                formData.append('f_chang_value', this.f_symptom_state);
                formData.append('f_admin_id', this.userDataAdminId);

                this.ajaxPost('', formData,'sendFuncResult');
                */
            },
            sendFuncResult(response) {
                this.showAlert('', '저장 되었습니다.',"close");
            },
            close(){
                this.isVisible=false;
                this.$parent.searchListFunc(this.page);
            }
        }
    }
</script>
<style scoped>
    .prdImgPopup_wrap {position: fixed;left:50%;top:50%;transform: translate(-50%, -50%); z-index: 9999;zoom:1;width: 500px;
        background: #fff;
        padding: 0 20px 20px;}
    .dimmed{background-color: #000;opacity: 0.5;position: fixed;left: 0;top: 0;right: 0;bottom: 0;z-index: 999;}
</style>
