<template>
    <div class="PopupUnderlyingDisease" v-show="isVisible==true">
        <section class="prdImgPopup_wrap">
            <div class="row">
                <div>
                    <div class="subHead">
                        <h3># 기저질환등록 </h3>
                    </div>
                    <table class="table table-profile">
                        <colgroup>
                            <col width="20%">
                            <col width="80%">
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">지역</th>
                            <td class="text-left">{{ data.f_region }}</td>
                        </tr>
                        <tr>
                            <th class="field">이름</th>
                            <td class="text-left">{{ data.f_patient_name }}</td>
                        </tr>
                        <tr>
                            <th class="field">확진일</th>
                            <td class="text-left">{{ (data.f_confirm_date != '0000-00-00 00:00:00') ? $moment(data.f_confirm_date).format('YYYY-MM-DD') : '' }}</td>
                        </tr>
                        <tr>
                            <th class="field">기저질환</th>
                            <td class="text-left">
                                <label class="mrg-right-10">
                                    <input type="checkbox" true-value="Y" false-value="N" v-model="f_diabetes_yn" /> 당뇨
                                </label>
                                <label class="mrg-right-10">
                                    <input type="checkbox" true-value="Y" false-value="N" v-model="f_dialysis_yn" /> 투석
                                </label>
                                <label class="mrg-right-10">
                                    <input type="checkbox" true-value="Y" false-value="N" v-model="f_lung_yn" /> 폐질환
                                </label>
                                <label class="mrg-right-10">
                                    <input type="checkbox" true-value="Y" false-value="N" v-model="f_cardiovascular_yn" /> 심혈관
                                </label>
                                <label class="mrg-right-10">
                                    <input type="checkbox" true-value="Y" false-value="N" v-model="f_anticancer_yn" /> 항암
                                </label>
                                <label class="mrg-right-10">
                                    <input type="checkbox" true-value="Y" false-value="N" v-model="f_mental_yn" /> 정신질환
                                </label>
                                <label class="mrg-right-10">
                                    <input type="checkbox" true-value="Y" false-value="N" v-model="f_pregment_yn" /> 임신
                                </label>
                              <label class="mrg-right-10">
                                <input type="checkbox" true-value="Y" false-value="N" v-model="f_bed_yn" /> 와상환자
                              </label>
                              <label class="mrg-right-10">
                                <input type="checkbox" true-value="Y" false-value="N" v-model="f_hbp_yn" /> 고혈압
                              </label>
                              <label class="mrg-right-10">
                                <input type="checkbox" true-value="Y" false-value="N" v-model="f_hpt_yn" /> 고지혈증
                              </label>
                              <label class="mrg-right-10">
                                <input type="checkbox" true-value="Y" false-value="N" v-model="f_etc_yn" /> 기타
                              </label>
                            </td>
                        </tr>
                        <tr v-if="f_lung_yn == 'Y'">
                          <th class="field">폐질환 상세</th>
                          <td class="text-left"><input type="text" class='input type2 width-100' v-model="f_etc_txt_1" /></td>
                        </tr>
                        <tr v-if="f_cardiovascular_yn == 'Y'">
                          <th class="field">심혈관 상세</th>
                          <td class="text-left"><input type="text" class='input type2 width-100' v-model="f_etc_txt_2" /></td>
                        </tr>
                        <tr v-if="f_etc_yn == 'Y'">
                          <th class="field">기타질환</th>
                          <td class="text-left"><input type="text" class='input type2 width-100' v-model="f_etc_txt" /></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row text-center no-pdd-top">
                    <div class="pull-left">
                        <button type="button" class="btn btn-lg btn-default" @click="isVisible=false">취소</button>
                    </div>
                    <div class="pull-right">
                        <button type="button" class="btn btn-lg btn-primary" @click="sendFunc">등록</button>
                    </div>
                </div>
            </div>
        </section>
        <div class="dimmed" @click="isVisible=false"></div>
    </div>
</template>

<script>
    export default {
        name: 'PopupUnderlyingDisease',
        data() {
            return {
                isVisible:false,
                data:[],
                f_diabetes_yn : "N",
                f_dialysis_yn : "N",
                f_lung_yn : "N",
                f_cardiovascular_yn : "N",
                f_anticancer_yn : "N",
                f_mental_yn : "N",
                f_pregment_yn : "N",
                f_bed_yn : "N",
                f_hbp_yn : "N",
                f_hpt_yn : "N",
                f_etc_yn : "N",
                f_etc_txt : "",
                f_etc_txt_1 : "",
                f_etc_txt_2 : "",
                page : '',
            }
        },
        // 계산된 속성
        computed: {
        },
        // 감시자
        watch: {
        },
        // $el 생성전
        created() {
        },
        // $el 생성후
        mounted() {

        },
        methods: {
            init(isVisible, obj, page){
                let params = `{"fIdx":${obj.f_idx}}`;
                let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
                this.ajaxGet('nocAssign/detail?params=' + queryString, null, 'initResult');

                this.isVisible = isVisible;

                this.page = page;

                /*
                this.data = obj;
                this.f_diabetes_yn = obj.f_diabetes_yn ? obj.f_diabetes_yn : "N";
                this.f_dialysis_yn = obj.f_dialysis_yn ? obj.f_dialysis_yn : "N";
                this.f_lung_yn = obj.f_lung_yn ? obj.f_lung_yn : "N";
                this.f_cardiovascular_yn = obj.f_cardiovascular_yn ? obj.f_cardiovascular_yn : "N";
                this.f_anticancer_yn = obj.f_anticancer_yn ? obj.f_anticancer_yn : "N";
                this.f_mental_yn = obj.f_mental_yn ? obj.f_mental_yn : "N";
                this.f_pregment_yn = obj.f_pregment_yn ? obj.f_pregment_yn : "N";
                this.f_bed_yn = obj.f_bed_yn ? obj.f_bed_yn : "N";
                this.f_hbp_yn = obj.f_hbp_yn ? obj.f_hbp_yn : "N";
                this.f_hpt_yn = obj.f_hpt_yn ? obj.f_hpt_yn : "N";
                this.f_etc_yn = obj.f_etc_yn ? obj.f_etc_yn : "N";
                this.f_etc_txt = obj.f_etc_txt;
                this.f_etc_txt_1 = obj.f_etc_txt_1;
                this.f_etc_txt_2 = obj.f_etc_txt_2;
                */
            },
            initResult(response){
                let result = response.list;
                console.log(result);

                this.data.f_idx = result.fidx;
                this.data.f_region = result.fregion;
                this.data.f_patient_name = result.fpatientName;
                this.data.f_confirm_date = result.fconfirmDate;

                this.f_diabetes_yn = result.fdiabetesYn;
                this.f_dialysis_yn = result.fdialysisYn;
                this.f_lung_yn = result.flungYn;
                this.f_cardiovascular_yn = result.fcardiovascularYn;
                this.f_anticancer_yn = result.fanticancerYn;
                this.f_mental_yn = result.fmentalYn;
                this.f_pregment_yn = result.fpregmentYn;
                this.f_bed_yn = result.fbedYn;
                this.f_hbp_yn = result.fhbpYn;
                this.f_hpt_yn = result.fhptYn;
                this.f_etc_yn = result.fetcYn;
                this.f_etc_txt = result.fetcTxt;
                this.f_etc_txt_1 = result.fetcTxt1;
                this.f_etc_txt_2 = result.fetcTxt2;

                console.log(this.data);
            },
            sendFunc(){
                /*
                let formData = new FormData();
                formData.append('api_name', 'patientDiseaseChange');  // API 이름
                formData.append('f_idx', this.data.f_idx);	                    // 확진자 기본키
                formData.append('f_admin_id', this.userDataAdminId);

                formData.append('f_diabetes_yn', this.f_diabetes_yn);
                formData.append('f_dialysis_yn', this.f_dialysis_yn);
                formData.append('f_lung_yn', this.f_lung_yn);
                formData.append('f_cardiovascular_yn', this.f_cardiovascular_yn);
                formData.append('f_anticancer_yn', this.f_anticancer_yn);
                formData.append('f_mental_yn', this.f_mental_yn);
                formData.append('f_pregment_yn', this.f_pregment_yn);
                formData.append('f_bed_yn', this.f_bed_yn);
                formData.append('f_hbp_yn', this.f_hbp_yn);
                formData.append('f_hpt_yn', this.f_hpt_yn);
                formData.append('f_etc_yn', this.f_etc_yn);
                */

                let data = {
                    "fIdx": this.data.f_idx,
                    "fDiabetesYn" : this.f_diabetes_yn,
                    "fDialysisYn" : this.f_dialysis_yn,
                    "fLungYn" : this.f_lung_yn,
                    "fCardiovascularYn": this.f_cardiovascular_yn,
                    "fAnticancerYn": this.f_anticancer_yn,
                    "fMentalYn" : this.f_mental_yn,
                    "fPregmentYn": this.f_pregment_yn,
                    "fBedYn": this.f_bed_yn,
                    "fHbpYn": this.f_hbp_yn,
                    "fHptYn": this.f_hpt_yn,
                    "fEtcYn": this.f_etc_yn,
                    "fEtcTxt": "",
                    "fEtcTxt1": "",
                    "fEtcTxt2": "",
                    "adminId": this.userDataAdminId
                }

                if (this.f_etc_yn == 'Y'){
                    data.fEtcTxt = this.f_etc_txt;
                } else {
                    data.fEtcTxt = "";
                }

                if (this.f_lung_yn == 'Y'){
                    data.fEtcTxt1 = this.f_etc_txt_1;
                } else {
                    data.fEtcTxt1 = "";
                }

                if (this.f_cardiovascular_yn == 'Y'){
                    data.fEtcTxt2 = this.f_etc_txt_2;
                } else {
                    data.fEtcTxt2 = "";
                }

                this.ajaxPost('nocAssign/diseaseUpdate', data,'sendFuncResult');
            },
            sendFuncResult(response) {
                this.showAlert('', "저장되었습니다.","close");
            },
            close(){
                this.isVisible=false;
                this.$parent.searchListFunc(this.page);
            }
        }
    }
</script>
<style scoped>
    .prdImgPopup_wrap {position: fixed;left:50%;top:50%;transform: translate(-50%, -50%); z-index: 9999;zoom:1;width: 500px;
        background: #fff;
        padding: 0 20px 20px;}
    .dimmed{background-color: #000;opacity: 0.5;position: fixed;left: 0;top: 0;right: 0;bottom: 0;z-index: 999;}
</style>
