import { render, staticRenderFns } from "./PopupSeverity.vue?vue&type=template&id=2954f04a&scoped=true&"
import script from "./PopupSeverity.vue?vue&type=script&lang=js&"
export * from "./PopupSeverity.vue?vue&type=script&lang=js&"
import style0 from "./PopupSeverity.vue?vue&type=style&index=0&id=2954f04a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2954f04a",
  null
  
)

export default component.exports